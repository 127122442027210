export class User {
  id: string;
  name: string;
  email: string;
  token: string;
  type: string;
  home_app_operator_logo_url: string;

  constructor(
    name: string,
    email: string,
    token: string,
    type: string,
    home_app_operator_logo_url: string
  ) {
    this.name = name;
    this.email = email;
    this.token = token;
    this.type = type;
    this.home_app_operator_logo_url = home_app_operator_logo_url;
  }

  static fromObject(obj) {
    return new User(
      obj.name,
      obj.email,
      obj.token,
      obj.type,
      obj.home_app_operator_logo_url
    );
  }

  isResident() {
    return this.type === 'Resi::Resident';
  }
}
