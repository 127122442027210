import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class LoaderService {
  constructor(public loadingCtrl: LoadingController) {}

  async presentLoader(message = 'Loading...', enableBackdropDismiss = false) {
    const loader = await this.loadingCtrl.create({ message });
    loader.backdropDismiss = enableBackdropDismiss;
    await loader.present();
    return loader;
  }
}
