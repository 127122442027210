import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { delay } from 'rxjs/operators';

declare const require: any;

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
  private cache: any = {};

  private simulateRequiredFieldsMissingForPOFChannelPublish = true;

  private nbTimesGetPropertyImportBatchCalled = 0;
  private nbTimesGetBookingCalled = 0;

  constructor() {
    this.importAll(require.context('../mock-data/', true, /\.json$/));
  }

  public getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public getRandomId(): number {
    return this.getRandomInt(100, 1000000);
  }

  public getRandomIdAsString(): string {
    return `${this.getRandomId()}`;
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    // Uncomment this to stop intercepting request
    // return next.handle( request );

    const urlWithParams: string = request.urlWithParams;
    const method: string = request.method;

    // This is here as without it, we can't see in the console the headers, they are there but lazy-loaded
    // I don't really understand how but if you want to debug and see the headers in the console, you will
    // only be able to see them after this line of code
    request.headers.keys();

    const response = this.buildResponseForSupportedUrl(request);

    if (response.status) {
      // tslint:disable-next-line: no-console (used only during development and it is very useful)
      console.warn(
        `AppMockBackEnd - url=${urlWithParams},   method=${method} - responseBody:`,
        response.body,
        `  requestBody=`,
        request.body
      );
      if (response.status === 200) {
        const delayResponse = response.delay ? response.delay : 20;
        return new Observable(resp => {
          resp.next(new HttpResponse(response));
          resp.complete();
        }).pipe(delay(delayResponse));
      } else if (response.status === 500) {
        return throwError(
          new HttpErrorResponse({
            status: 500,
            statusText: 'Internal server error',
            error: {
              message: 'Internal server error'
            }
          })
        );
      } else if (response.status === 400) {
        return throwError(
          new HttpErrorResponse({
            status: 400,
            statusText: 'Bad Request',
            error: {
              errors: response.errors
            }
          })
        );
      } else {
        return throwError(
          new HttpErrorResponse({
            status: 401,
            statusText: 'unauthorized',
            error: 'jwt_error'
          })
        );
      }
    } else {
      // All unhandled request goes through
      return next.handle(request);
    }
  }

  private buildResponseForSupportedUrl(request: HttpRequest<any>): any {
    console.log(
      'TONIO MockHttpInterceptor buildResponseForSupportedUrl request=',
      request
    );
    const url: string = request.urlWithParams;
    const method: string = request.method;

    if (method === 'GET') {
      if (/properties/.test(url)) {
        return this.buildResponseObjectFromCacheFile('./properties.get.json');
      }

      // else if (/calendars\/[0-9]+\?start_date=/.test(url)) {
      //   return this.buildResponseObjectFromCacheFile('./calendar.get.json');
      // }
    } else if (method === 'PUT') {
      // if (/calendars\/[0-9]+\/free/.test(url)) {
      //   return {
      //     status: 200,
      //     body: null
      //   };
      // }
    }

    return {
      status: 0
    };
  }

  private buildPUTResponseForJSONApi(
    requestBody: any,
    forceError = false,
    delayResponse?: number
  ) {
    return {
      status: forceError ? 500 : 200,
      body: requestBody,
      delay: delayResponse
    };
  }

  private buildPUTResponseForJSONApiForRelationshipUpdate(
    requestBody: any,
    cacheFileName: string,
    forceError = false
  ) {
    const cacheFile = this.getFromCache(cacheFileName);

    // Find attributes that match the ID in input, it's not perfect as it could be a completely new ID, but that's something
    requestBody.data.forEach(item => {
      const itemMatchingInCacheFile = cacheFile.data.find(
        itemInCache => itemInCache.id === item.id
      );
      if (itemMatchingInCacheFile) {
        item.attributes = itemMatchingInCacheFile.attributes;
      } else {
        // We could not find a model in our mock file that match the id, we will use the first one as a back up
        item.attributes = cacheFile.data[0].attributes;
        console.error(
          `Can't find an existing Model in our mock json file for ${item.type}:${item.id}`
        );
      }
    });

    return {
      status: forceError ? 500 : 200,
      body: requestBody
    };
  }

  private buildResponseObjectFromCacheFile(
    cacheFileKey: string,
    url?: string,
    delayResponse?: number
  ): any {
    let id: string = null;

    if (url) {
      id = this.extractIdFromUrl(url);
    }

    const body = this.getFromCache(cacheFileKey);

    if (id) {
      body.data.id = id;
    }

    return {
      status: 200,
      body,
      delay: delayResponse
    };
  }

  private extractIdFromUrl(url: string): string {
    const regexpSearchForId = /\d+/;

    const test = url.match(regexpSearchForId);
    const id = test[0];
    return id;
  }

  private importAll(iFileList: any) {
    iFileList.keys().forEach((key: string) => {
      this.cache[key] = iFileList(key);
      return;
    });
  }

  private getFromCache(cacheKey: string) {
    if (!this.cache.hasOwnProperty(cacheKey)) {
      throw new Error(
        `The file ${cacheKey} does not exist, did you create it? check spelling...`
      );
    }

    return JSON.parse(JSON.stringify(this.cache[cacheKey]));
  }
}
