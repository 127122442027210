import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AuthService } from './../auth-service/auth.service';
import { Config } from '../../../config/config';
import {
  GetPropertiesApiResponse,
  Property
} from '../../models/property.model';

@Injectable()
export class PropertyService {
  constructor(private authService: AuthService, public http: HttpClient) {}

  async getProperties(params): Promise<Property[]> {
    const currentUser = await this.authService.getCurrentUser();
    let endpoint = Config.BASE_ENDPOINT_URL;
    if (currentUser.type === 'Resi::Resident') {
      endpoint = Config.RESI_BASE_ENDPOINT_URL;
    }

    return this.http
      .get(`${endpoint}/properties`, { params })
      .pipe(map((res: GetPropertiesApiResponse) => res.properties))
      .toPromise();
  }
}
