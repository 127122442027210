import { Injectable } from '@angular/core';
import { Config } from 'src/config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtInterceptorSkipHeader } from '../interceptors/jwt.interceptor';

import { Resident } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ResidentOnboardingService {
  endpoint: string = Config.RESI_BASE_ENDPOINT_URL;

  constructor(private http: HttpClient) {}

  getDevelopment(development) {
    const params = {
      development_slug: development
    };
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set(JwtInterceptorSkipHeader, '');
      this.http
        .get<void>(
          `${this.endpoint}/resident_onboarding_signup/development?development_slug=${development}`,
          { headers: headers }
        )
        .pipe(map(res => res['resident']))
        .toPromise()
        .then(resolve)
        .catch(reject);
    });
  }

  signUp(formValues, development) {
    const params = {
      ...formValues,
      development_slug: development
    };
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set(JwtInterceptorSkipHeader, '');
      this.http
        .post<void>(
          `${this.endpoint}/resident_onboarding_signup/sign_up`,
          params,
          { headers: headers }
        )
        .pipe(map(res => res['resident']))
        .toPromise()
        .then(resolve)
        .catch(reject);
    });
  }

  show() {
    return this.http
      .get<Resident>(`${this.endpoint}/resident_onboarding`)
      .pipe(map(res => res['resident']))
      .toPromise();
  }

  update(
    formValues,
    development,
    onboarding_status,
    accepted_documents?
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const params = {
        development_slug: development,
        unit_claim: {
          onboarding_status: onboarding_status,
          name: formValues.home.unit,
          agreement_accepted: formValues.agreement.accepted,
          features: { ...formValues.unit_features },
          policies: { ...formValues.policies },
          accepted_documents: { ...accepted_documents }
        }
      };

      this.http
        .put<void>(`${this.endpoint}/resident_onboarding`, params)
        .pipe(map(res => res['resident']))
        .toPromise()
        .then(resolve)
        .catch(reject);
    });
  }
}
