import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import * as Sentry from '@sentry/browser';
import { FullstoryModule } from 'ng-fullstory';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './services/auth-service/auth.service';
import { ToasterService } from './services/toaster-service/toaster-service';
import { SettingsService } from './services/settings-service/settings-service';
import { UserRepo } from './services/user-repo/user-repo';
import { LoaderService } from './services/loader-service/loader-service';
import { CalendarService } from './services/calendar-service/calendar-service';
import { VersionService } from './services/version-service/version-service';
import { ChannelService } from './services/channel-service/channel-service';
import { PropertyService } from './services/property-service/property-service';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LavandaAPIService } from './services/lavanda-api/lavanda-api.service';
import { I18nTranslateService } from './services/i18n-translate/i18n-translate.service';

import { Config } from '../config/config';
import { ENVIRONMENT } from 'src/config/environment';
import { MockHttpInterceptor } from 'src/app/app.mock-backend.service';

Sentry.init({
  dsn: 'https://3299407582534d80b6d344c3c6915a3f@sentry.io/304994'
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (Config.production) {
      Sentry.captureException(error.originalError || error);
    }
  }
}

export const ENV_PROVIDERS = ENVIRONMENT.FEATURE.ENABLE_MOCK_BACKEND
  ? [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MockHttpInterceptor,
        multi: true
      }
    ]
  : [];

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    IonicStorageModule.forRoot(),
    FullstoryModule.forRoot({
      fsOrg: undefined,
      fsDebug: true
    }),
    AppRoutingModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService,
    UserRepo,
    ToasterService,
    SettingsService,
    LoaderService,
    CalendarService,
    VersionService,
    ChannelService,
    PropertyService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: 'lavandaAPIService', useClass: LavandaAPIService },
    { provide: 'lavandaI18n', useClass: I18nTranslateService },
    ...ENV_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
