import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

type ToastPosition = 'top' | 'bottom' | 'middle';

@Injectable()
export class ToasterService {
  constructor(public toastCtrl: ToastController) {}

  async showToast(
    message,
    position: ToastPosition = 'bottom',
    duration = 2000
  ) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: duration,
      position: position,
      showCloseButton: duration ? false : true
    });

    await toast.present();
  }

  async stick(message, position: ToastPosition = 'top') {
    const toast = await this.toastCtrl.create({
      message: message,
      position: position,
      showCloseButton: false
    });

    await toast.present();

    return toast;
  }
}
