import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import {
  GetBookingApiResponse,
  CalendarDetail,
  GetCalendarsApiResponse,
  CalendarEventApi
} from '../../models';

import { Config } from '../../../config/config';
import { AuthService } from '../auth-service/auth.service';

@Injectable()
export class CalendarService {
  endpoint: string = Config.BASE_ENDPOINT_URL;

  constructor(public http: HttpClient, private authService: AuthService) {}

  async getCalendars(page): Promise<any> {
    await this.setEndpoint();

    return this.http
      .get(`${this.endpoint}/calendars/?page=${page}`)
      .pipe(map((res: GetCalendarsApiResponse) => res.calendars))
      .toPromise()
      .then(
        calendars => {
          return new Promise((resolve, reject) => {
            resolve(calendars);
          });
        },
        error => {
          return new Promise((resolve, reject) => {
            resolve([]);
          });
        }
      );
  }

  async getCalendar(
    calendarId,
    startDate,
    endDate
  ): Promise<CalendarEventApi[]> {
    await this.setEndpoint();

    return this.http
      .get(
        `${this.endpoint}/calendars/${calendarId}?start_date=${startDate}&end_date=${endDate}`
      )
      .pipe(map((res: CalendarDetail) => res.calendar))
      .toPromise();
  }

  async blockCalendar(calendarId, notes, startDate: string, endDate: string) {
    await this.setEndpoint();

    const url = `${this.endpoint}/calendars/${calendarId}/close?start_date=${startDate}&end_date=${endDate}`;

    return this.http.put(url, { notes }).toPromise();
  }

  async freeCalendar(calendarId, startDate: string, endDate: string) {
    await this.setEndpoint();

    const url = `${this.endpoint}/calendars/${calendarId}/free?start_date=${startDate}&end_date=${endDate}`;

    return this.http.put(url, {}).toPromise();
  }

  async getBookingDetails(bookingId) {
    await this.setEndpoint();

    return this.http
      .get(`${this.endpoint}/calendars/${bookingId}/booking`, {})
      .pipe(map((res: GetBookingApiResponse) => res.booking))
      .toPromise();
  }

  private async setEndpoint() {
    const user = await this.authService.getCurrentUser();

    if (user && user.isResident()) {
      this.endpoint = Config.RESI_BASE_ENDPOINT_URL;
    }
  }
}
