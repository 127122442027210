import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { map } from 'rxjs/operators';

@Injectable()
export class VersionService {
  static readonly VERSION = '1.3.4';

  constructor(public http: HttpClient, public storage: Storage) {}

  getCurrentVersion() {
    return VersionService.VERSION;
  }

  getLatestVersion() {
    const currentUrl = window.location.href;
    const isLocalInstance = currentUrl.match('http://localhost');
    const versionEndpoint = isLocalInstance
      ? 'http://localhost:5000/home/version'
      : '/home/version';

    return this.http
      .get(versionEndpoint)
      .pipe(map(res => (res as any).version))
      .toPromise();
  }

  async checkIfCurrentVersionIsOutdated(): Promise<any> {
    try {
      const latestVersion = await this.getLatestVersion();
      const currentVersion = this.getCurrentVersion();
      return currentVersion !== latestVersion;
    } catch (error) {
      console.error('Error getting latest version: ', error);
    }
  }

  setUpdateIsInProgress(updateIsInProgress: boolean) {
    this.storage.set('updateIsInProgress', updateIsInProgress);
  }

  getUpdateIsInProgress(): Promise<any> {
    return this.storage.get('updateIsInProgress');
  }

  resetUpdateIsInProgress() {
    this.storage.set('updateIsInProgress', false);
  }
}
