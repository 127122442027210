import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { NavController, MenuController } from '@ionic/angular';

import { AuthService } from '../auth-service/auth.service';
import { ResidentOnboardingService } from '../resident-onboarding.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  private hasSeenTutorial = false;
  private signupDisabled = false;

  constructor(
    private authService: AuthService,
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private storage: Storage,
    private router: Router,
    private residentOnboardingService: ResidentOnboardingService
  ) {
    const successfulLogin =
      window.location.hash.indexOf('#access_token=') !== -1;
    this.signupDisabled =
      window.location.hash.indexOf(
        '#error=access_denied&error_description=Signup%20disabled'
      ) !== -1;
    if (successfulLogin) {
      this.navigateToSavedPath();
    }
  }

  navigateToSavedPath() {
    this.storage
      .get('redirectToPathAfterLogin')
      .then(redirectToPathAfterLogin => {
        if (redirectToPathAfterLogin) {
          this.navCtrl.navigateRoot(redirectToPathAfterLogin || '/dashboard');
        }
      });
  }

  savePath(toUrl) {
    if (
      toUrl.indexOf('#access_token=') === -1 &&
      toUrl.indexOf('#error=') === -1
    ) {
      this.storage.set('redirectToPathAfterLogin', toUrl);
    }
  }

  navigateToLogin() {
    if (this.signupDisabled) {
      this.navCtrl.setDirection('root');
      this.router.navigate(['/login', { error: 'signup_disabled' }]);
    } else {
      this.navCtrl.navigateRoot('/login');
    }
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const currentUser = await this.authService.getCurrentUserFromService();
    return this.shouldActivate(currentUser, state.url);
  }

  private async shouldActivate(
    currentUser: object | null,
    toUrl: string
  ): Promise<boolean> {
    let shouldActivate = false;

    if (!currentUser) {
      this.savePath(toUrl);
      this.navigateToLogin();
      return false;
    } else {
      if (currentUser['type'] === 'Resi::Resident') {
        shouldActivate = await this.continueResidentOnboarding(toUrl);
      }
      if (['OwnerUser', 'AuthIdentity::OwnerUser'].includes(currentUser['type'])) {
        shouldActivate = await this.showTutorialOnFirstLogin(toUrl);
        if (toUrl === '/checklist') {
          this.navCtrl.navigateRoot('/dashboard');
        }
      }
      if (['InternalUser', 'AuthIdentity::InternalUser'].includes(currentUser['type'])) {
        shouldActivate = true;
      }
    }

    this.updateMainMenu(shouldActivate, toUrl);
    return shouldActivate;
  }

  private async showTutorialOnFirstLogin(toUrl): Promise<boolean> {
    if (this.hasSeenTutorial) {
      return true;
    } else {
      if (toUrl === '/tutorial') {
        return true;
      } else {
        const hasJustSeenTutorial = await this.storage.get('hasSeenTutorial');
        if (hasJustSeenTutorial) {
          this.hasSeenTutorial = true;
          return true;
        } else {
          this.navCtrl.navigateRoot('/tutorial');
          return false;
        }
      }
    }
  }

  private async continueResidentOnboarding(toUrl): Promise<boolean> {
    let development;
    const onboardingStatus = await this.residentOnboardingService
      .show()
      .then(resident => {
        return this.storage.set('resident_onboarding', resident).then(_ => {
          development = resident.development.slug;
          if (resident.unit_claim) {
            return resident.unit_claim.onboarding_status;
          } else {
            return 'home';
          }
        });
      });

    if (['finished', 'checklist'].includes(onboardingStatus)) {
      return true;
    } else {
      if (toUrl.indexOf('/onboarding') !== -1) {
        return true;
      } else {
        this.navCtrl.navigateRoot(`/onboarding/${development}`);
        return false;
      }
    }
  }

  private updateMainMenu(willSeeLoggedInRoute: boolean, routeUrl?: string) {
    const showMainMenu = willSeeLoggedInRoute && routeUrl !== '/tutorial';
    this.menuCtrl.enable(showMainMenu);
  }
}
