import { Injectable } from '@angular/core';

import { IChannelList } from '../../models';

@Injectable()
export class ChannelService {
  channelList: IChannelList[] = Array<IChannelList>();

  constructor() {
    this.generateChannels();
  }

  generateChannels() {
    this.channelList.push({
      displayName: 'Airbnb',
      fieldName: 'Airbnb',
      displayColour: '#8963a8'
    });

    this.channelList.push({
      displayName: 'HomeAway',
      fieldName: 'HomeAway',
      displayColour: '#72c4b9'
    });

    this.channelList.push({
      displayName: 'Booking.com',
      fieldName: 'Booking.com',
      displayColour: '#3373b4'
    });

    this.channelList.push({
      displayName: 'Direct',
      fieldName: 'Direct',
      displayColour: '#3b214c'
    });

    this.channelList.push({
      displayName: 'Expedia',
      fieldName: 'Expedia',
      displayColour: '#fbc108'
    });

    this.channelList.push({
      displayName: 'Spotahome',
      fieldName: 'Spotahome',
      displayColour: '#32b496'
    });

    this.channelList.push({
      displayName: 'Unbooked',
      fieldName: 'Unbooked',
      displayColour: '#f2f2f2'
    });

    this.channelList.push({
      displayName: 'Blocked',
      fieldName: 'Blocked',
      displayColour: '#acacac'
    });

    this.channelList.push({
      displayName: 'Available',
      fieldName: 'Available',
      displayColour: '#fff'
    });
  }

  getChannelsForDropDowns() {
    return this.channelList.filter(channel => {
      return (
        channel.fieldName !== 'Unbooked' &&
        channel.fieldName !== 'Blocked' &&
        channel.fieldName !== 'Available'
      );
    });
  }

  getChannelColour(channelName): string {
    // lookup in channelList and return the displayColour for the matching channel
    return this.channelList
      .filter(channel => channel.fieldName === channelName)
      .map(channel => channel.displayColour)
      .find(colourName => colourName !== undefined);
  }
}
