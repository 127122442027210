export const ENVIRONMENT = {
  PRODUCTION: true,
  NAME: 'production',
  USER_TRACKING: true,
  API_URL: 'https://api.lavanda.app/api/home',
  RESI_API_URL: 'https://api.lavanda.app/resi/api',
  GOOGLE_RECAPTCHA_SITEKEY: '6Le4YZgUAAAAALwxUOEHCPwB_J4k40DWxxX6InRz',
  AUTH0_REDIRECT_URI: 'https://home.lavanda.app/',
  AUTH0_CONNECTION: ['lvd-am-PRODUCTION', 'google-oauth2'],
  AUTH0_AUDIENCE: 'lvdam-production',
  AUTH0_CONFIG: {
    clientID: 'cqRlPdKoBE7vvBoq1Z2kfA3fPdm0Vlga',
    domain: 'login.lavanda.app',
    responseType: 'token id_token',
    scope: 'openid email profile'
  },
  FEATURE: {
    ENABLE_MOCK_BACKEND: false
  }
};
