import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LavandaAPIServiceInterface } from '@techlavanda/shared';

import { Config } from 'src/config/config';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LavandaAPIService implements LavandaAPIServiceInterface {
  private endpoints = {
    home: Config.BASE_ENDPOINT_URL,
    resi: Config.RESI_BASE_ENDPOINT_URL
  };

  constructor(private http: HttpClient, private authService: AuthService) {}

  /** @DEPRECATED Use the "request" method instead, that enables you to customise the entire URL to be called. */
  public async fetch<T>(
    method: 'POST' | 'GET',
    path: string,
    params: object
  ): Promise<T> {
    try {
      return this.http
        .request<T>(method, `${this.endpoints.home}/${path}`, { body: params })
        .toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  public request<T>(
    method: 'POST' | 'GET',
    url: string,
    params: HttpParams,
    body?: object,
    options?: object
  ): Promise<T> {
    return this.http
      .request<T>(method, url, { params, body, ...options })
      .toPromise();
  }

  public getCurrentUser() {
    return this.authService.getCurrentUser() as any;
  }

  public getEndpoint(target: string): string {
    const scope = target === 'default' ? 'home' : target;
    return this.endpoints[scope];
  }
}
