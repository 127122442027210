import { Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { ENVIRONMENT } from 'src/config/environment';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserService } from '../user-service/user.service';
import { Storage } from '@ionic/storage';
import { UserRepo } from '../user-repo/user-repo';
import { take } from 'rxjs/operators';
import { User } from '../../models/user.model';

declare const Intercom: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth0 = new auth0.WebAuth(ENVIRONMENT.AUTH0_CONFIG);
  accessToken: string;
  expiresAt: any;
  user: any;
  tokenRefresh: Observable<string>;

  constructor(
    private storage: Storage,
    private userService: UserService,
    private userRepo: UserRepo
  ) {}

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.clearPreviousAndSetNewSession(authResult);
      }
    });
  }

  getCurrentUser(): Promise<User> {
    return this.userRepo
      .getCurrentUser()
      .pipe(take(1))
      .toPromise();
  }

  getToken(): Observable<string> {
    return this.isTokenValid().pipe(
      mergeMap(isTokenValid => {
        if (isTokenValid) {
          return this.storage.get('access_token');
        } else {
          if (this.tokenRefresh) {
            // console.log('Token has expired, refresh is in progress...');
            return this.tokenRefresh;
          } else {
            // console.log('Token has expired, refreshing...');
            this.tokenRefresh = from(this.checkSession());
            return this.tokenRefresh;
          }
        }
      })
    );
  }

  isTokenValid(): Observable<boolean> {
    return from(this.isAuthenticated());
  }

  isAuthenticated(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.storage.get('expires_at').then((expiresAt: string) => {
        if (expiresAt) {
          const expires = JSON.parse(expiresAt);
          const isTokenValid = new Date().getTime() < expires;
          resolve(isTokenValid);
        } else {
          resolve(false);
        }
      });
    });
  }

  clearPreviousAndSetNewSession(authResult): void {
    this.storage.remove('access_token');
    this.storage.remove('id_token');
    this.storage.remove('expires_at');

    const currentDate = new Date().getTime();
    const exp = authResult.expiresIn * 1000 + currentDate;
    const expiresAt = JSON.stringify(exp);

    this.storage.set('access_token', authResult.accessToken);
    this.storage.set('id_token', authResult.idToken);
    this.storage.set('expires_at', expiresAt);
  }

  async getCurrentUserFromService(): Promise<User> {
    const isAuthenticated = await this.isAuthenticated();
    if (!isAuthenticated) {
      return null;
    }

    let currentUser = await this.getCurrentUser();
    if (currentUser) {
      return currentUser;
    }

    currentUser = await this.userService.getCurrentUser().then(user => {
      if (user) {
        const userInstance = User.fromObject(user);
        this.userRepo.setCurrentUser(userInstance);
        return userInstance;
      } else {
        return null;
      }
    });

    return currentUser;
  }

  checkSession(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession(
        {
          audience: ENVIRONMENT.AUTH0_AUDIENCE,
          redirectUri: ENVIRONMENT.AUTH0_REDIRECT_URI,
          scope: ''
        },
        (err, authResult) => {
          this.tokenRefresh = null;
          if (authResult) {
            this.clearPreviousAndSetNewSession(authResult);
            resolve(authResult.accessToken);
          }
        }
      );
    });
  }

  logout(): void {
    this.userRepo.resetCurrentUser();
    this.storage.remove('access_token');
    this.storage.remove('id_token');
    this.storage.remove('expires_at');
    Intercom('shutdown');
    this.auth0.logout({ returnTo: ENVIRONMENT.AUTH0_REDIRECT_URI });
  }

  loginAfterOnboardingSignup(username, password) {
    return new Promise((resolve, reject) => {
      this.auth0.client.login(
        {
          realm: ENVIRONMENT.AUTH0_CONNECTION[0],
          username: username,
          password: password,
          audience: ENVIRONMENT.AUTH0_AUDIENCE,
          scope: ENVIRONMENT.AUTH0_CONFIG.scope
        },
        (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.clearPreviousAndSetNewSession(authResult);
            this.checkSession();
            resolve(true);
          }
        }
      );
    });
  }
}
