import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoggedInGuard } from './services/logged-in-guard/logged-in.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [LoggedInGuard],
    loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule'
  },
  {
    path: 'checklist',
    canActivate: [LoggedInGuard],
    loadChildren:
      './pages/onboarding-checklist/onboarding-checklist.module#OnboardingChecklistPageModule'
  },
  {
    path: 'properties',
    canActivate: [LoggedInGuard],
    loadChildren:
      './pages/property-list/property-list.module#PropertyListPageModule'
  },
  {
    path: 'calendar',
    canActivate: [LoggedInGuard],
    loadChildren: './pages/calendar/calendar.module#CalendarPageModule'
  },
  {
    path: 'account',
    canActivate: [LoggedInGuard],
    loadChildren: './pages/account/account.module#AccountPageModule'
  },
  {
    path: 'tutorial',
    canActivate: [LoggedInGuard],
    loadChildren: './pages/tutorial/tutorial.module#TutorialPageModule'
  },
  {
    path: 'onboarding/signup/:development',
    canActivate: [],
    loadChildren:
      './pages/onboarding-signup/onboarding-signup.module#OnboardingSignupPageModule'
  },
  {
    path: 'onboarding/:development',
    canActivate: [LoggedInGuard],
    loadChildren: './pages/onboarding/onboarding.module#OnboardingPageModule'
  },
  {
    path: 'onboarding/checklist/:development',
    canActivate: [LoggedInGuard],
    loadChildren:
      './pages/onboarding-checklist/onboarding-checklist.module#OnboardingChecklistPageModule'
  },
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginPageModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
