import { ENVIRONMENT } from './environment';

export class Config {
  public static readonly production = ENVIRONMENT.PRODUCTION;
  public static readonly ENVIRONMENT: string = ENVIRONMENT.NAME;
  public static readonly BASE_ENDPOINT_URL: string = ENVIRONMENT.API_URL;
  public static readonly RESI_BASE_ENDPOINT_URL: string =
    ENVIRONMENT.RESI_API_URL;
  public static readonly GOOGLE_RECAPTCHA_KEY =
    ENVIRONMENT.GOOGLE_RECAPTCHA_SITEKEY;
}
