export const DEFAULT_FORMAT_DATE = `YYYY-MM-DD`;

export interface FullCalendarEvent {
  id?: string | number;
  className?: string[] | string;
  textColor?: string;
  color?: string;
  title: string;
  allDay: boolean;
  start: string;
  end: string;
  extendedProps: {
    status: EventStatus;
    note: string;
  };
}

export enum EventStatus {
  available = 'available',
  booked = 'booked',
  confirmed = 'confirmed',
  unavailable = 'unavailable'
}

export interface CalendarEventApi {
  date: string;
  status: EventStatus;
  end_date: string; // Format YYYY-DD-MM
  note: string;
  platform: string;
  guestName: string;
  booking_id: number;
  calendar_object_id: any;
}

export function isCalendarEventApi(
  value: FullCalendarEvent | CalendarEventApi
): value is CalendarEventApi {
  return (
    value instanceof Object &&
    value.constructor === Object &&
    value.hasOwnProperty('date') &&
    value.hasOwnProperty('status') &&
    value.hasOwnProperty('end_date') &&
    value.hasOwnProperty('note') &&
    value.hasOwnProperty('guestName') &&
    value.hasOwnProperty('booking_id')
  );
}

export interface CalendarDetail {
  calendar: CalendarEventApi[];
}

export interface Calendar {
  address: object;
  code: string;
  id: string;
  picture: string;
}

export interface GetCalendarsApiResponse {
  calendars: Calendar[];
}
