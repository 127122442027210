import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Config } from '../../config/config';
import { AuthService } from '../services/auth-service/auth.service';
import { VersionService } from '../services/version-service/version-service';

export const JwtInterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const lavandaEndpoint = Config.BASE_ENDPOINT_URL;
    const lavandaResiEndpoint = Config.RESI_BASE_ENDPOINT_URL;

    const headers = {
      'X-Client-App': 'app',
      'X-Client-Version': VersionService.VERSION
    };

    // Only intercept requests to Lavanda API
    if (
      !req.headers.has(JwtInterceptorSkipHeader) &&
      req.url &&
      (req.url.indexOf(lavandaEndpoint) >= 0 ||
        req.url.indexOf(lavandaResiEndpoint) >= 0)
    ) {
      return this.authService.getToken().pipe(
        switchMap((token: string) => {
          if (token) {
            headers['Authorization'] = `Bearer ${token}`;
            const reqWithExtraHeaders = req.clone({ setHeaders: headers });
            return next.handle(reqWithExtraHeaders);
          } else {
            return next.handle(req);
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
