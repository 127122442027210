import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Events } from '@ionic/angular';

import { User } from '../../models';

@Injectable()
export class UserRepo {
  private user: BehaviorSubject<User | null> = new BehaviorSubject(undefined);

  constructor(private storage: Storage, public events: Events) {
    this.restoreCurrentUser();
  }

  setCurrentUser(user) {
    this.user.next(user);

    this.events.publish('userSavedToLs');

    return this.storage.set('currentUser', JSON.stringify(user));
  }

  getCurrentUser(): Observable<User | null> {
    // tslint:disable-next-line: max-line-length
    return this.user.asObservable().pipe(filter(value => value !== undefined)); // Undefined is skiped because we are still loading user info
  }

  resetCurrentUser() {
    this.user.next(null);
    return this.storage.remove('currentUser');
  }

  private restoreCurrentUser() {
    this.storage.get('currentUser').then(userData => {
      const currentUser = userData
        ? User.fromObject(JSON.parse(userData))
        : null;
      this.user.next(currentUser);
    });
  }
}
