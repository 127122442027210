import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/config/config';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  endpoint: string = Config.BASE_ENDPOINT_URL;

  constructor(private http: HttpClient) {}

  getCurrentUser() {
    return this.http.get<any>(`${this.endpoint}/current_user`).toPromise();
  }

  requestPassword(email) {
    const encodedEmail = encodeURIComponent(email);
    return this.http
      .get<any>(
        `${this.endpoint}/user/recover_password?email=${encodedEmail}&auth0=true`
      )
      .toPromise();
  }
}
