import { Injectable } from '@angular/core';

// Home does not have i18n yet, but some of our NPM packages already require translations
const temporaryTranslations = {};

@Injectable({
  providedIn: 'root'
})
export class I18nTranslateService {
  constructor() {}

  translate(string: string, args?) {
    return temporaryTranslations[string] || string;
  }
}
