import { Component, OnInit } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Events } from '@ionic/angular';
import { Fullstory } from 'ng-fullstory';

import { VersionService } from './services/version-service/version-service';
import { AuthService } from './services/auth-service/auth.service';
import { User } from './models';
import { Storage } from '@ionic/storage';
import { ResidentOnboardingService } from './services/resident-onboarding.service';
import { ENVIRONMENT } from 'src/config/environment';

declare const Intercom: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  currentAppVersion = VersionService.VERSION;
  user: User;
  logoUrl: string;
  logoClass: string;
  residentUser = false;

  public appPages = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'dashboard'
    },
    {
      title: 'Calendar',
      url: '/calendar',
      icon: 'calendar'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    private authService: AuthService,
    public events: Events,
    private storage: Storage,
    private residentOnboardingService: ResidentOnboardingService,
    private fullstory: Fullstory
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    this.initializeAuth();
    this.initializeUser();
    this.subscribeToCompletionOfOnboarding();
    this.events.subscribe('userSavedToLs', () => this.initializeUser());
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  initializeAuth() {
    this.authService.handleAuthentication();
  }

  setLogoAttributes(logo_url) {
    if (logo_url && this.residentUser) {
      this.logoClass = 'development-logo';
    } else {
      this.logoClass = 'home-logo';
    }

    this.logoUrl = logo_url || '/assets/imgs/logos/home-by-lavanda-black.png';
  }

  private initializeUser() {
    this.authService.getCurrentUser().then(user => {
      if (user) {
        this.user = user;
        this.setResidentUserAttributes();
        this.setLogoAttributes(this.user.home_app_operator_logo_url);
      }

      this.updateFullstory(user);
      this.initializeIntercom();
    });
  }

  private subscribeToCompletionOfOnboarding() {
    this.events.subscribe('addChecklistMenuItem', () => {
      this.obtainResidentOnboardingStatus();
    });
  }

  private setResidentUserAttributes() {
    if (this.user.type === 'Resi::Resident') {
      this.obtainResidentOnboardingStatus();
      this.residentUser = true;
    } else {
      this.storage.remove('resident_onboarding');
    }
  }

  private obtainResidentOnboardingStatus() {
    this.storage.get('resident_onboarding').then(resident_onboarding => {
      if (resident_onboarding) {
        this.appendChecklistToMenu();
      } else {
        this.residentOnboardingService.show().then(resident => {
          this.storage.set('resident_onboarding', resident).then(() => {
            this.appendChecklistToMenu();
          });
        });
      }
    });
  }

  private appendChecklistToMenu() {
    if (!this.appPages.find(menuItem => menuItem.title === 'Checklist')) {
      this.appPages.unshift({
        title: 'Checklist',
        url: '/checklist',
        icon: 'check-all'
      });
    }
  }

  private initializeIntercom() {
    if (this.user) {
      Intercom('boot', {
        app_id: 'l0vq3578',
        name: this.user.name,
        email: this.user.email
      });
    }
  }

  updateFullstory(user?: User) {
    const isLavandaUser =
      user && user.email && user.email.toLowerCase().endsWith('getlavanda.com');
    const recordVisit = !isLavandaUser && ENVIRONMENT.USER_TRACKING;

    if (recordVisit) {
      this.initFullstory();

      if (user) {
        this.fullstory.login({
          user_id: this.user.id,
          name: this.user.name,
          email: this.user.email
        });
      }
    }
  }

  private initFullstory() {
    const snippet = `
      window['_fs_debug'] = false;
      window['_fs_host'] = 'fullstory.com';
      window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
      window['_fs_org'] = 'KWPA0';
      window['_fs_namespace'] = 'FS';
      (function(m,n,e,t,l,o,g,y){
      if (e in m) {if(m.console && m.console.log) {
        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
      } return;}
      g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
      o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
      y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
      g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};
      g.event=function(i,v,s){g('event',{n:i,p:v},s)};
      g.anonymize=function(){g.identify(!!0)};
      g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
      g.log = function(a,b){g("log",[a,b])};
      g.consent=function(a){g("consent",!arguments.length||a)};
      g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
      g.clearUserCookie=function(){};
      g.setVars=function(n, p){g('setVars',[n,p]);};
      g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
      if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
      g._v="1.3.0";
      })(window,document,window['_fs_namespace'],'script','user');`;

    if (!(window as any).FS) {
      const elem = document.createElement('script');
      elem.textContent = snippet;
      document.body.appendChild(elem);
    }
  }

  openAccount() {
    this.navCtrl.navigateRoot('/account');
  }
}
