import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './../auth-service/auth.service';
import { Config } from '../../../config/config';

export type BankDetails = UKAccount | IBANAccount;

export interface BankAccount {
  account_type: 'UK' | 'IBAN';
}

export interface UKAccount extends BankAccount {
  account_type: 'UK';
  holder: string;
  sort_code: string;
  bank_account: string;
}

export interface IBANAccount extends BankAccount {
  account_type: 'IBAN';
  holder: string;
  swiftbic: string;
  iban: string;
  reference?: string;
}

interface APIBankDetailsResponse {
  data: Array<{ bank_details: BankDetails }>;
}

@Injectable()
export class SettingsService {
  endpoint: string = Config.BASE_ENDPOINT_URL;

  constructor(public authService: AuthService, public http: HttpClient) {}

  getBankDetails(): Promise<BankDetails> {
    return new Promise((resolve, reject) => {
      this.http
        .get<APIBankDetailsResponse>(`${this.endpoint}/bank_details`)
        .toPromise()
        .then(response => {
          resolve(
            response &&
              response.data &&
              response.data[0] &&
              response.data[0].bank_details
          );
        })
        .catch(reject);
    });
  }

  requestToChangeBankDetails(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get<void>(`${this.endpoint}/bank_details/request_to_change`)
        .toPromise()
        .then(resolve)
        .catch(reject);
    });
  }

  updateBankDetails(bank_details: BankDetails, token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const params = {
        bank_details,
        token
      };

      this.http
        .put<void>(`${this.endpoint}/bank_details/update_all_for_owner`, params)
        .toPromise()
        .then(resolve)
        .catch(reject);
    });
  }
}
